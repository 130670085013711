<!--
 * @Descripttion: window窗口
 * @Author: yunfei
 * @Date: 2022-12-29 16:06:16
-->
<template>
  <div class="extension_box">
    <!-- <el-divider></el-divider> -->
    <el-row>
      <el-col :sm="24">
        <el-card>
          <template #header>
            <div class="extension_tit">关注我的微信公众号</div>
          </template>
          <div>
            <img
              style="width: 200px"
              class="_img"
              src="../assets/img/extension/my_gzh.png"
              alt=""
            />
          </div>
        </el-card>
      </el-col>
      <el-col :sm="24">
        <el-card>
          <template #header>
            <div class="extension_tit">我的书架</div>
          </template>
          <!-- 列表 -->
          <el-row class="novel_box">
            <el-col
              v-for="item in list"
              :key="item.name"
              :md="12"
              :lg="8"
              :xl="6"
            >
              <div :class="item.poor ? 'list_flex poor_div' : 'list_flex'">
                <div>
                  <img v-if="src" :src="item.src" />
                  <img v-if="!src" src="../assets/img/extension/zwfm.jpg" />
                </div>
                <div>
                  <h4>
                    {{ item.name }}
                  </h4>
                  <p style="color: #96979a">时间：{{ item.time }}</p>
                  <p v-html="item.des"></p>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const list = ref([
      {
        name: "《书名已隐藏1》",
        poor: true,
        time: "2021-10-1 至 2022-4-23",
        src: "",
        des: "故事写的，自己都看不下去了。重写吧 哈哈😅,",
      },
      {
        name: "《书名已隐藏2》",
        poor: true,
        time: "2021-5-3 至 2022-10-16",
        src: "",
        des: "和【鱼香】 pk 失败了，被迫停更。惨啊 😨",
      },
      {
        name: "《书名已隐藏3》",
        poor: false,
        time: "2021-10-16 至 now",

        src: "",
        des: "冲 冲 ✍",
        link: "",
      },
    ]);

    return { list };
  },
};
</script>

<style>
.extension_box ._img {
  width: 100%;
  max-width: 900px;
}
.extension_box .txt_center {
  text-align: center;
}
.extension_box .extension_tit {
  font-weight: 800;
  color: #0c9298;
}
/* 书架 */
.novel_box img {
  width: auto;
  height: 200px;
}
/* 小说 */
.list_flex {
  display: flex;
  flex-direction: row;
  padding: 7px 10px 2px;
  border-radius: 4px;
  transition: all 0.5s;
  cursor: pointer;
}

.list_flex:hover {
  background: #a4cbff;
}

.list_flex div {
  flex: 1;
}

.list_flex h4 {
  padding: 4px 0;
}

.list_flex p {
  padding-top: 15px;
  font-size: 14px;
  line-height: 20px;
}
/* 小说 激活状态 */
.poor_div {
  cursor: not-allowed;
}
.poor_div:hover {
  background: #d9d9d9;
}
</style>